import { Observable, ServerError } from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import {
    Authenticator,
    AuthenticatorEdge,
    AuthenticatorQueryResult,
    TenantAccessMode,
    TenantAuthConfig,
} from "@/shared/services/graphql/generated/consumer-graph-types";

const FORBIDDEN = "forbidden";
const UNAUTHORIZED = "unauthorized";
const ANONYMOUS_STRATEGY = "AnonymousStrategy";
const TEST_STRATEGY = "TestStrategy";

interface AuthToken {
    token: string;
    expiresInSeconds: number;
}

let token: string;
let authConfig: TenantAuthConfig;
let authenticators: Authenticator[] = [];
let anonymousAuthenticator: Authenticator;

export const loginLink = onError(({ networkError, operation, forward }) => {
    const statusCode = (networkError as ServerError)?.statusCode;

    if (statusCode === 401 && !!token) {
        return new Observable((observer) => {
            getTokenAndStartTokenAutoRefresh()
                .then(() => {
                    const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                    };

                    forward(operation).subscribe(subscriber);
                })
                .catch((error: any) => {
                    if (error.message === UNAUTHORIZED) {
                        location.reload();
                        return;
                    }

                    observer.error(error);
                });
        });
    }

    if (statusCode === 403) {
        return new Observable((observer) => {
            observer.error("You don't have the rights to use the app. Please contact your administrator.");
        });
    }
});

export const authLink = setContext((_, { headers }) => {
    return {
        headers: {
            ...headers,
            authorization: getAuthHeader(),
        },
    };
});

export const getAuthHeader = () => {
    return token ? `Bearer ${token}` : "";
};

export const initAuthentication = (config: TenantAuthConfig) => {
    const authenticatorQueryResult = config?.authenticators as AuthenticatorQueryResult;
    if (!authenticatorQueryResult.authenticators?.length) throw new Error("No authenticator defined.");
    authConfig = config;
    setAuthenticators(authenticatorQueryResult.authenticators as AuthenticatorEdge[]);
};

export const tryGetToken = async () => {
    try {
        const result = await getTokenAndStartTokenAutoRefresh();
        return result;
    } catch (e: any) {
        return false;
    }
};

export const forceLogin = async (authenticator: Authenticator) => {
    await redirectToLoginPage(authenticator.url);
};

const getTokenAndStartTokenAutoRefresh = async () => {
    token = "";
    const expiresIn = await getAndSetJWT();

    if (expiresIn) {
        tokenAutoRefresh(expiresIn);
        return true;
    }

    return false;
};

const setAnonymousAuthenticator = (auth: Authenticator) => {
    anonymousAuthenticator = auth;
};

const setAuthenticators = (authList: AuthenticatorEdge[]) => {
    const activeAuths = authList.filter((x) => x.node?.status !== "hidden");

    //check if tenant is for e2e ui testing
    const testStrategyAuth = activeAuths.find(
        (val: AuthenticatorEdge) => val?.node?.strategyName.toUpperCase() === TEST_STRATEGY.toUpperCase()
    );

    if (testStrategyAuth) {
        authenticators = [testStrategyAuth.node as Authenticator];
        return;
    }

    //check if tenant has anonymous strategy
    if (isMixedMode() || isAnonymousMode()) {
        const anonymousAuth = activeAuths.find(
            (val: AuthenticatorEdge) => val?.node?.strategyName === ANONYMOUS_STRATEGY
        );
        if (anonymousAuth) {
            setAnonymousAuthenticator(anonymousAuth.node as Authenticator);
        }
    }
    if (!isAnonymousMode()) {
        authenticators = activeAuths
            .filter((val: AuthenticatorEdge) => val?.node?.strategyName !== ANONYMOUS_STRATEGY)
            .map((x) => x.node) as Authenticator[];
    }
};

const redirectToLoginPage = async (authenticatorUrl: string) => {
    const loginDomain = `${window.location.protocol}//${window.location.host}`;
    const loginURL = new URL(authenticatorUrl);
    const callbackURL = new URL(window.location.href);

    loginURL.searchParams.append("loginDomain", loginDomain);
    loginURL.searchParams.append("callbackURL", callbackURL.href);

    window.location.href = loginURL.href;
};

const getTokenFromCookie: () => Promise<AuthToken> = async () => {
    const tokenResponse = await fetch("/auth/tokens", {
        credentials: "include",
    });

    if (tokenResponse.status === 401) throw new Error(UNAUTHORIZED);
    if (tokenResponse.status === 403) throw new Error(FORBIDDEN);
    if (!tokenResponse.ok) throw new Error(tokenResponse.statusText);

    const tokenObject: AuthToken = await tokenResponse.json();
    return tokenObject;
};

const getAndSetJWT = async () => {
    const jwt = await getTokenFromCookie();
    token = jwt.token;
    return jwt.expiresInSeconds;
};

const tokenAutoRefresh = (expiresInSeconds: number, authenticate?: boolean) => {
    const intervalInMs = expiresInSeconds * 1000 * 0.9;
    setTimeout(async () => {
        try {
            const _expiresInSeconds = await getAndSetJWT();
            if (_expiresInSeconds) tokenAutoRefresh(_expiresInSeconds, authenticate);
        } catch (e: any) {
            if (e.messages === UNAUTHORIZED) location.reload();
        }
    }, intervalInMs);
};

export const getAccessMode = () => {
    return authConfig.accessMode;
};

export const isMixedMode = () => {
    return authConfig.accessMode === TenantAccessMode.mixed;
};

export const isAnonymousMode = () => {
    return authConfig.accessMode === TenantAccessMode.anonymous;
};

export const isAuthenticatedMode = () => {
    return authConfig.accessMode === TenantAccessMode.authenticated;
};

export const getAuthenticators = () => {
    return authenticators;
};

export const getAnonymousAuthenticator = () => {
    if (!anonymousAuthenticator) throw new Error("No anonymous authenticator defined");
    return anonymousAuthenticator;
};

//Todo find better solutions, but right now router.beforeEach runs before login
export const waitForAuthentication = () => {
    return new Promise((resolve) => {
        if (token) {
            resolve(true);
            return;
        }

        const interval = setInterval(() => {
            if (token) {
                clearInterval(interval);
                resolve(true);
            }
        }, 100);
    });
};
