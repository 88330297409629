<template>
    <div class="facet-dropdown column">
        <div class="title qit-property-key ellipsis">{{ facet.title }}</div>
        <q-btn-dropdown
            no-caps
            unelevated
            no-route-dismiss
            color="white"
            @before-show="onOpenedHandler"
            class="full-width facet-dropdown-button"
            align="between"
            menu-anchor="bottom left"
            menu-self="top left"
            v-model="dropDownVisible"
            :disable="disable"
            :data-qs="`facet-dropdown-${facet.id}`"
            dropdown-icon="fa-solid fa-caret-down"
        >
            <template #label>
                <DropdownMultiField
                    v-if="selected?.length > 0 && facet.displayOptions.isMultiSelect"
                    :selected="selected"
                    :on-remove="removeValue"
                    :max-tags="MAX_TAGS"
                    :disable="disable"
                />
                <DropdownSingleField
                    v-else-if="selected?.length > 0"
                    :selected="selected[0]"
                    :on-remove="removeValue"
                    :disable="disable"
                />
                <span v-else class="q-ml-sm">
                    {{ $t("core.All") }}
                </span>
            </template>
            <DropdownTemplate
                :loading="loading"
                :facet="facet"
                :aggregations="aggregations"
                :facet-values="facetValues ?? []"
                :selected="selected"
                :on-select="valueChangedHandler"
                @on-modal-closed="hideDropdown()"
                @on-filter-changed="onFilterChanged"
            />
        </q-btn-dropdown>
    </div>
</template>

<script setup lang="ts">
import { onMounted, ref, toRefs, watchEffect } from "vue";
import { Facet, FacetSelected } from "../facets.model";
import DropdownMultiField from "./field/dropdown-multi-field.vue";
import DropdownSingleField from "./field/dropdown-single-field.vue";
import DropdownTemplate from "@/shared/facets/dropdown/content/dropdown-template.vue";
import { MIN_SEARCH_CHARS_KEYWORD_FILTER, useFacetDropdownLazy } from "@/shared/facets/dropdown/facet-dropdown-lazy";

export interface FacetDropdownProps {
    facet: Facet;
    selectedIds: string[];
    facetCollectionId: string;
    disable?: boolean;
    isLast?: boolean;
    fetchAggregation?: (facetID: string) => Promise<Record<string, number>>;
}

const props = defineProps<FacetDropdownProps>();

const emits = defineEmits<{
    (e: "onValueChanged", facetSelected: FacetSelected): void;
    (e: "onOpenFilter", facet: Facet): void;
    (e: "onLastElementMounted"): void;
}>();

const MAX_TAGS = 5;

const dropDownVisible = ref<boolean>(false);
const { selectedIds } = toRefs(props);
const loading = ref<boolean>(false);
const filterPhrase = ref<string>("");

const { facetValues, selected, load, aggregations, refetchAggregation } = useFacetDropdownLazy(
    props.facetCollectionId,
    props.facet,
    selectedIds,
    props.fetchAggregation
);

onMounted(() => {
    if (props.isLast) {
        emits("onLastElementMounted");
    }
});

watchEffect(() => {
    //load values first to show preselected values else load values on open dropdown
    if (selectedIds.value?.length > 0 && facetValues.value === undefined) load();
});

const onOpenedHandler = async () => {
    emits("onOpenFilter", props.facet);
    loading.value = true;

    //load values only once when opening dropdown
    await load();

    loading.value = false;
};

const valueChangedHandler = (selected: string[]) => {
    const facetsSelected: FacetSelected = {
        id: props.facet.id,
        referencedId: props.facet.referencedId,
        type: props.facet.facetType,
        values: selected,
    };

    emits("onValueChanged", facetsSelected);
};

const removeValue = (id: string) => {
    const newSelected = props.selectedIds.filter((val) => val !== id);

    const facetsSelected: FacetSelected = {
        id: props.facet.id,
        type: props.facet.facetType,
        referencedId: props.facet.referencedId,
        values: newSelected,
    };

    emits("onValueChanged", facetsSelected);
};

const hideDropdown = () => {
    dropDownVisible.value = false;
};

const onFilterChanged = (newFilterPhrase: string) => {
    if (newFilterPhrase.length >= MIN_SEARCH_CHARS_KEYWORD_FILTER) {
        filterPhrase.value = newFilterPhrase;
        refetchAggregation(newFilterPhrase);
    } else if (filterPhrase.value.length > newFilterPhrase.length) {
        filterPhrase.value = "";
        refetchAggregation(filterPhrase.value);
    }
};
</script>

<style lang="scss" scoped>
.facet-dropdown-button {
    min-height: 38px;
    padding: 0 $spacing-m 0 0;
}
.title {
    min-height: 17px;
}
</style>
