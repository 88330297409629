<template>
    <div>
        <router-link :to="routeToAssembly" class="router-link">
            <div
                class="item-content"
                :class="{
                    'item-content-selected': isSelected,
                }"
            >
                <div
                    class="item-content-container"
                    data-qs="assembly-navigation-hide-overlay-button"
                    @click="$emit('hideOverlay')"
                >
                    <div class="skeleton-square preview-image-container" v-if="loading"></div>

                    <div v-else class="preview-image-container">
                        <PreviewImage :image-src="mechanicData.previewImage?.url" fallback-icon="fa-gear" />
                    </div>
                    <div class="mechanic-title">
                        <div v-if="loading">
                            <div class="skeleton-line percent80" />
                            <div class="skeleton-line percent50 margin-top-small" />
                        </div>
                        <div v-else>
                            <div v-if="title" class="title-content qit-text-ellipsis">
                                <span>{{ title }}</span>
                            </div>
                            <div v-else>
                                <span>{{ mechanicData.articleId }}</span>
                            </div>

                            <div class="mechanic-tile-article-number">
                                <span>{{ mechanicData.articleNumber }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="navigation-button" v-if="hasSubAssemblies">
                    <div
                        class="list-navigation-button flex q-pa-md items-center"
                        @click.prevent="internSubAssemblyButtonClicked"
                    >
                        <i class="fa-solid fa-chevron-right medium-text-color" />
                    </div>
                </div>
            </div>
        </router-link>
    </div>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { Mechanic } from "@/shared/services/graphql/generated/consumer-graph-types";
import { RouteLocationRaw } from "vue-router";
import { getDataQueryLanguages } from "@/shared/services/providers/language-provider";
import { createMechanicPartsListQuery } from "../../graphql/mechanics-partslist.query";
import { useQuery } from "@vue/apollo-composable";
import PreviewImage from "@/shared/components/image-item/preview-image.vue";

const props = withDefaults(
    defineProps<{
        product: string;
        mechanicData: Mechanic;
        subAssemblyButtonClicked: () => void;
        isSelected?: boolean;
        asset?: string;
        assemblyId?: string;
        routeToAssembly: RouteLocationRaw;
    }>(),
    {
        isSelected: false,
    }
);

defineEmits(["hideOverlay"]);

const mechanicFilterVariables = computed(() => {
    return {
        filter: {
            andGroup: [
                {
                    equals: {
                        parentAssemblyId: props.mechanicData.assemblyId ?? "",
                    },
                },
                {
                    equals: {
                        isAssembly: true,
                    },
                },
            ],
        },
        assetId: props.asset,
        productId: props.product,
        acceptedLanguages: getDataQueryLanguages(),
        first: 1,
    };
});

const { result, loading } = useQuery(
    createMechanicPartsListQuery({ withPreviewImages: false }),
    mechanicFilterVariables,
    { fetchPolicy: "cache-first" }
);

/**
 * fügt ein neues level an assemblyPath an und
 * geht ein level in de navigation nach unten
 */
function internSubAssemblyButtonClicked() {
    props.subAssemblyButtonClicked();
}

const hasSubAssemblies = computed(() => {
    return result.value?.mechanics.mechanics.length > 0;
});

const title = computed(() => {
    return props.mechanicData?.localizations?.title ?? props.mechanicData?.teasers?.title ?? undefined;
});
</script>

<style lang="scss" scoped>
//liste
.item-content-container {
    display: flex;
    width: 100%;
    overflow: hidden;
}

.item-content-container:hover {
    background-color: var(--q-highlight-hover);
}

.item-content {
    display: flex;
    border: 1px solid $default-border-color;
    border-radius: $default-border-radius;
    font-weight: $semi-bold;
    width: 100%;
}

.list-navigation-button {
    background: transparent;
    color: $light-border-color;
    width: 100%;
    height: 100%;
}

.navigation-button {
    border-left: 1px solid $default-border-color;
    flex-shrink: 0;
    flex-grow: 0;
}

.title-content {
    padding-right: $spacing-s;
    flex-grow: unset;
    overflow: hidden;
}

.mechanic-title {
    width: 3em;
    flex-grow: 3;
    flex-shrink: 1;
    display: flex;
    align-items: center;
    overflow: hidden;

    & > div {
        width: 100%;
    }
}

.navigation-button:hover {
    background-color: var(--q-highlight-hover);
}

.preview-image-container {
    border: 1px solid $default-border-color;
    border-radius: $default-border-radius;
    margin: $spacing-m;
    width: $preview-image-width-compact;
    flex-shrink: 0;
    flex-grow: 0;
    height: $preview-image-height-compact;
}

.item-content-selected {
    background: var(--q-highlight);
}
.mechanic-tile-article-number {
    font-size: $smaller-font-size;
    color: $light-text-color;
    margin-top: $spacing-s;
}
</style>
