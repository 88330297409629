<!-- 
    Anzeige der drei 'wichtigsten' Dokumente eines Produkts, Assets oder Artikels in jeweils einer Kachel.
    Zusätzlich gibt es einen Button für den Sprung in die gesamte Dokumentation.
        
-->
<template>
    <ExpansionPanel
        id="relatedDocs"
        icon="fa-regular fa-book icon-light-color"
        :label="$t('documentation.Documentation')"
        v-if="isWidgetVisible && docs"
    >
        <template v-if="!isLessThanMedium" #header-right>
            <router-link
                :to="pathToDocumentations"
                class="router-link"
                v-if="relatedDocumentContext !== RelatedDocumentContext.Article"
            >
                <QitButton flat :label="$t('documentation.Show full documentation')" />
            </router-link>
        </template>
        <div
            data-qs="related-documents-widget"
            class="fit row"
            :class="{ 'q-col-gutter-md': !isLessThanMedium, gutter: isLessThanMedium }"
        >
            <div class="col-md-4 col-xs-12" v-for="(item, index) in docs" :key="item.contentId">
                <router-link :to="getDocumentationLink(item)">
                    <ListCard
                        card-type="small"
                        :title="item.title"
                        :locale-tag="item.language"
                        :test-id="`related-document-${index}`"
                        :fallback-icon="getIcon(item)"
                    >
                    </ListCard>
                </router-link>
            </div>
            <div class="flex flex-grow justify-center">
                <router-link
                    :to="pathToDocumentations"
                    class="router-link"
                    v-if="isLessThanMedium && relatedDocumentContext !== RelatedDocumentContext.Article"
                >
                    <QitButton flat :label="$t('documentation.Show full documentation')" />
                </router-link>
                <div
                    v-if="
                        relatedDocumentContext === RelatedDocumentContext.Article &&
                        total &&
                        total > Number(docs.length)
                    "
                >
                    <QitButton
                        v-if="!fetchMoreDocsLoading"
                        flat
                        :label="$t('documentation.Load more content')"
                        @click="fetchMoreDocs(docs ? docs[docs?.length - 1] : undefined)"
                        icon="fa-regular fa-chevron-down"
                    />
                    <q-spinner-ios v-else size="2em" />
                </div>
            </div>
        </div>
    </ExpansionPanel>
</template>

<script lang="ts" setup>
import {
    getDocumentationQuery,
    RelatedDocumentContext,
} from "@/abilities/documentation/composables/related-documents/related-documents-query-factory";
import { Documentation } from "@/abilities/documentation/model/documentation.model";
import { WidgetContext } from "@/shared/environment/widgets/widget-context";
import { SearchContext } from "@/shared/search/search.types";
import { computed, nextTick, ref, watch } from "vue";
import ExpansionPanel from "@/shared/components/expansion/expansion-panel.vue";
import QitButton from "@/shared/components/buttons/qit-button.vue";
import { useRoute } from "vue-router";
import { CommonRouteAliases } from "@/shared/environment/common-route-aliases";
import { useDocumentRoute } from "@/shared/router/document-route";
import { useAbilityRoute } from "@/shared/environment/ability-route";
import ListCard from "@/shared/components/card/list-card.vue";
import { useScreenSize } from "@/shared/screen/composables/screen-size.ts";
import { useDocQueryParams } from "@/shared/composables/doc-query-params.ts";

const INIT_COUNT = 6;
const FETCH_MORE_COUNT = 12;
const route = useRoute();

const props = defineProps<{
    widgetContext: WidgetContext;
}>();

const fetchMoreDocsLoading = ref<boolean>(false);

const { getPathToDocumentationDetailPageForSearch } = useDocumentRoute();
const { getPathToChild } = useAbilityRoute();
const { isLessThanMedium } = useScreenSize();
const { queryParams } = useDocQueryParams();

function getProductId(): string | undefined {
    if (props.widgetContext.__type === "product") {
        return props.widgetContext.productId;
    }

    if (props.widgetContext.__type === "asset" || props.widgetContext.__type === "mechanicArticle") {
        return props.widgetContext.product;
    }

    return undefined;
}

function getAssetId(): string | undefined {
    if (props.widgetContext.__type === "asset" || props.widgetContext.__type === "mechanicArticle") {
        return props.widgetContext.asset;
    }

    return undefined;
}

function getArticleId(): string | undefined {
    if (props.widgetContext.__type === "commonArticle") {
        return props.widgetContext.articleId;
    }

    if (props.widgetContext.__type === "mechanicArticle") {
        return props.widgetContext.mechanicArticleId;
    }

    return undefined;
}

function getIcon(item: Documentation) {
    if (item.type === "ContentMap") {
        return "fa-book info-color";
    } else if (item.type === "Topic") {
        return "fa-file-lines topic-icon";
    }
    return "";
}

const relatedDocumentContext = computed(() => {
    if (getArticleId()) return RelatedDocumentContext.Article;
    if (getAssetId()) return RelatedDocumentContext.Asset;
    if (getProductId()) return RelatedDocumentContext.Product;

    return undefined;
});

const documentQuery = getDocumentationQuery(
    INIT_COUNT,
    relatedDocumentContext.value,
    getProductId() ?? "",
    getAssetId() ?? "",
    getArticleId() ?? ""
);

const { loading, docs, total, fetchMore } = documentQuery();

const isWidgetVisible = computed(() => {
    return relatedDocumentContext.value !== undefined && !loading.value && docs.value && docs.value.length > 0;
});

watch(isWidgetVisible, async () => {
    if (!isWidgetVisible.value) return;

    await nextTick(() => {
        const id = route.hash.replace("#", "");
        if (id) document.getElementById(id)?.scrollIntoView();
    });
});

const pathToDocumentations = computed(() => {
    if (Object.keys(queryParams.value).length === 0) {
        return getPathToChild(CommonRouteAliases.documentations, {});
    }

    return getPathToChild(CommonRouteAliases.documentations, {}, queryParams.value);
});

function getDocumentationLink(item: Documentation) {
    //widget props are not typed and not consistent
    //productId is set by productId prop in product detail page and by product prop in mechanic article detail page
    //assetId is set by assetId prop in product detail page and by asset prop in mechanic article detail page
    const context: SearchContext = {
        product: getProductId(),
        asset: getAssetId(),
        text: "",
    };
    return getPathToDocumentationDetailPageForSearch(item.contentId, item.topicId, [], context);
}

const fetchMoreDocs = async (lastDoc: Documentation | undefined) => {
    if (!lastDoc) {
        return;
    }
    fetchMoreDocsLoading.value = true;
    try {
        await fetchMore(lastDoc.cursor, FETCH_MORE_COUNT);
    } finally {
        fetchMoreDocsLoading.value = false;
    }
};
</script>

<style lang="scss" scoped>
.gutter {
    gap: 12px;
}
</style>
