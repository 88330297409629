<template>
    <DropdownSearchField v-if="searchEnabled" @on-value-changed="filterItems" />
    <q-list v-if="items.length > 0">
        <q-item
            v-for="item in filteredItems"
            :key="item.id"
            class="item"
            tag="label"
            :data-qs="`dropdown-item-${item.id}`"
        >
            <q-item-section avatar>
                <q-checkbox v-model="selection" :val="item.id" @click="() => onClick()" />
            </q-item-section>
            <q-item-section>
                <q-item-label> {{ getLabel(item) }}</q-item-label>
            </q-item-section>
        </q-item>
    </q-list>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { FacetValue } from "../../facets.model";
import DropdownSearchField from "@/shared/facets/dropdown/content/dropdown-search-field.vue";

const props = defineProps<{
    items: FacetValue[];
    selected: FacetValue[];
    aggregations: Record<string, number>;
    searchEnabled?: boolean;
}>();

const emits = defineEmits<{
    onSelectionChanged: [selectedIds: string[]];
    onFilterChanged: [filterPhrase: string];
}>();

const selection = ref<string[]>(props.selected.map((x) => x.id));
const filteredItems = ref<FacetValue[]>(props.items);

watch(
    () => props.selected,
    () => {
        selection.value = props.selected.map((x) => x.id);
    }
);

watch(
    () => props.items,
    () => {
        filteredItems.value = props.items;
    }
);

const onClick = () => {
    emits("onSelectionChanged", [...selection.value]);
};

const getLabel = (item: FacetValue) => {
    return `${item.text} ${
        props.aggregations && props.aggregations[item.id] > 0 ? `(${props.aggregations[item.id] ?? "0"})` : "(0)"
    }`;
};

const filterItems = (filterPhrase: string) => {
    emits("onFilterChanged", filterPhrase);
};
</script>

<style lang="scss" scoped>
.item {
    min-height: 38px;
    padding: 0 $spacing-m 0 $spacing-m;
}
.q-item__section--avatar {
    min-width: initial;
    padding: 0;
}
</style>
